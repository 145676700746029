import './App.css';
import './Admin.css'
import React, { useState, useEffect } from "react";

import firebase from 'firebase/compat/app';
import { useTranslation } from 'react-i18next';

// import { SignOutButton } from '../auth/signOutButton';
import CopieurList from './CopieurList/CopieurList.jsx';
import Releve from './Releve/Releve.jsx';
import AddData from './AddData.jsx';
import ErrorsList from './ErrorsList.jsx';
// import { getUserData } from '../users/getUserData';
import { getCurrentUser } from '../auth/getCurrentUser';
import { getCurrentUserInfo } from '../users/getCurrentUserInfo';

function Home(props) {

  const[showReleve,setShowReleve ]=useState(true);
  const[resultReleveApp,setResultReleveApp]=useState(null)
  const[resultErrorApp,setResultErrorApp]=useState(null)
  const[resultAddData,setResultAddData]=useState(null)
  const[resultNom,setResultNom]=useState(null)
  const[afficherReleve,setAfficherReleve]=useState(false)
  const[afficherAddData,setAfficherAddData]=useState(false)
  const[erreurElectron,setErreurElectron]=useState(false)
  const[nomErreurElectron,setNomErreurElectron]=useState('')
  const[afficherErreur,setAfficherErreur]=useState(false)
  const[allData,setAllData]=useState(null)
  const actualUser = getCurrentUser();
  const[handleCopieurId,setHandleCopieurId]=useState(null)
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertStyle, setAlertStyle] = useState(null);

  const { t } = useTranslation();
 


  useEffect(() => {
    setResultReleveApp(null);
    setResultErrorApp(null);
    setAfficherReleve(false);
    setAfficherErreur(false);
    setAfficherAddData(false)
   
}, []);

// on recupere data de firestore qd user connecté
  
useEffect(() => {
  const loadData = async () => {
      const result = await getCurrentUserInfo();
      // console.log('donnees firestore', result)
      setAllData(result);
      // console.log('donnees firestore', allData) // NE FONCTIONNE PAS, VOIR LE USE EFFECT SUIVANT POUR VOIR LES DONNEES de allData
  }

  loadData();
}, []);

// Console.log des données de firestore après mise à jour de allData
useEffect(() => {
  // console.log('donnees firestore après mise à jour', allData);
}, [allData]);


  useEffect(() => {
    document.title = "Gestion Copieurs by Elyote"
  }, [])


  async function fermerInfo() {

   setAfficherReleve(false)
   setAfficherAddData(false)
   setResultReleveApp(null)

   // On pars du principe que si l'utilisateur clique sur 'fermer' on n'affiche plus le releve
   // Donc on update le type à start + on met result_net.data.additional_data à null
   /* var db = firebase.firestore();
   db.collection("customers").doc(actualUser.id).collection("printing_counters").doc(handleCopieurId).update({
      "type": "start",
      "result_net.data.additional_data": null
    }) */
    // Requête POST pour envoyer les infos au cloud function
    let url = "";
    if (process.env.NODE_ENV === 'development') {
      url = "https://react-cloudfunction-iru7ymzqca-uc.a.run.app";
    } else {
      url = "https://react-cloudfunction-opdnvnmzja-uc.a.run.app";
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action : 'fermer_info',
          userUid: actualUser.id,
          printerId: handleCopieurId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la requête POST:', error);
    }
}

  
// recup nouveau result ds etat remonté au parent
  var handleResultReleve = (val, error) => {
    if(error==="no"){
        setResultReleveApp(val);
        setAfficherReleve(true)
    }
  
  }

  // recup result_useful (du composant Start1) remonté au parent App pour affichage
  // NEW :recup result_net (du composant Start1) remonté au parent App pour affichage
  var handleResultStart = (val, error) => {
    if(val !== null){
    setResultErrorApp(val);}
  
    if(error==="yes"){
    setAfficherErreur(true)
   }else if(error==="no"){
    // setAfficherErreur(false) // FIX 12/2023 : on ferme l'erreur seulement si user clique sur 'fermer' (voir fermerErreurs)
  }
  }

  var handleErrorElectron = (nom) => {
    setErreurElectron(true)
    setNomErreurElectron(nom)
    setAfficherErreur(true)
    console.log('probleme de connexion sur app electron')
  }
    // recup additional_data de result_useful (ds Start1)  remonté au parent App pour l' afficher 
    // NEW : recup additional_data de result_net (ds Start1)  remonté au parent App pour l' afficher
  var handleAdditionalData = (val,nom) => {
  
    setResultAddData(val);
    setResultNom(nom)
    setAfficherAddData(true)
  }

 

  async function fermerErreurs(){
    setResultErrorApp(null)
  
    setAfficherErreur(false)
    setErreurElectron(false)

    // On pars du principe que si l'utilisateur clique sur 'fermer' on n'affiche plus l'erreur
   // Donc on update le result_net.error_statut à "no" + on met result_net.error à null + on met result_net.data.notification à null
    /* var db = firebase.firestore();
    db.collection("customers").doc(actualUser.id).collection("printing_counters").doc(handleCopieurId).update({
      "result_net.error_statut": "no",
      "result_net.error": null,
      "result_net.data.notification": null
    }) */

    // Requête POST pour envoyer les infos au cloud function
    const url = "https://react-cloudfunction-opdnvnmzja-uc.a.run.app";

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action : 'fermer_erreurs',
          userUid: actualUser.id,
          printerId: handleCopieurId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la requête POST:', error);
    }
    
  }
  
  // Récupération des alertes depuis Firestore et affichage avec style
  useEffect(() => {
    const db = firebase.firestore();
    const docRef = db.collection('alerts').doc('react');
  
    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (data.display) {
          // console.log(data.style);
          setAlertMessage(data.message);
          setAlertStyle(data.style);
        } else {
          setAlertMessage(null);
        }
      }
    });
  
    return () => unsubscribe();
  }, []);
   
 
    return (
      <><div className="App">

        {alertMessage && (
          <div style={alertStyle}>
            {alertMessage}
          </div>
        )}

        {afficherErreur === true ?
       
          <ErrorsList  resultReleveApp={resultErrorApp} fermerErreurs={fermerErreurs} erreurElectron={erreurElectron} nomErreurElectron={nomErreurElectron} afficherErreur={afficherErreur}/>
          :
          <div></div>}



        {(afficherReleve) ?
          
            <Releve
              fermerInfo={fermerInfo}
              resultReleveApp={resultReleveApp} resultAddData={resultAddData} resultNom={resultNom} afficherReleve={afficherReleve}/>
          : <div></div>}

        {(afficherAddData) ?
          
          <AddData 
            fermerInfo={fermerInfo}
            resultAddData={resultAddData} resultNom={resultNom} afficherAddData={afficherAddData}/>
        : <div></div>}


        <div className="CopieurContainer">

          {/* TEXTE D'INFORMATION TEMPORAIRE (laisser commenté si aucun message à afficher)
           <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <small style={{ width: '70%', color: '#e69138' }}>Le problème du relevé des compteurs est en partie résolu, vous devriez pouvoir utiliser le relevé désormais. Nous sommes encore sur la résolution du problème. Nous nous excusons pour la gêne occasionnée.</small>
          </div> */}

          <div className="CopieurList">

            <CopieurList 
              showReleve={showReleve}
              donnees={allData} clientUid={actualUser.id} handleResultReleve={handleResultReleve} handleResultStart={handleResultStart} 
              handleErrorElectron={handleErrorElectron} handleAdditionalData={handleAdditionalData}
              setAfficherAddData={setAfficherAddData} setAfficherErreur={setAfficherErreur} setAfficherReleve={setAfficherReleve}
              handleCopieurId={setHandleCopieurId}
              />
          </div>
        </div>


        <div className="Admin">

          <a className='BAdmin' href={`https://www.simply-copy.com/printers-list.php?signin_token=${actualUser.id} `} target="_blank" rel="noopener noreferrer">
            {t('home.copiers')}
          </a>

          <a
            className='BAdmin'
            href={`https://www.simply-copy.com/account${actualUser.client_type === "BV" ? '-bv' : ''}.php?signin_token=${actualUser.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >

            {t('home.account')}
          </a>

          <a href="https://www.simply-copy.com/documentation/" className='BAdmin' target="_blank"  rel="noopener noreferrer">
          {t('home.help')}
          </a>

          <a href="/deconnexion" className='BAdmin'>
          {t('home.logout')}
          </a>
          {/* <SignOutButton /> */}
         
        </div>

      </div>
      </>
    )

}


export default Home;

