import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute } from '../auth/ProtectedRoute.jsx';
import Home from './Home.jsx';
import { Deconnexion } from './Deconnexion';
import SignInForm from '../auth/signInForm';

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const uid = queryParameters.get('uid');
  const debug = queryParameters.get('debug') === 'true';

  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('uid') ? true : false);
  const [uidValue, setUidValue] = useState(uid);

  return (
    <Router>
      <Routes>
        {/* Route publique */}
        <Route
          path="/"
          element={
            <SignInForm
              uid={uidValue}
              isAuthenticated={isAuthenticated}
              setIsAuthenticated={setIsAuthenticated}
              setUidValue={setUidValue}
              debug={debug}
            />
          }
        />
        {/* Route de déconnexion */}
        <Route path="/deconnexion" element={<Deconnexion />} />

        {/* Route protégée */}
        <Route
          path="/home"
          element={
            <ProtectedRoute isAuthed={isAuthenticated} uid={uidValue} isLoading={false}>
              <Home clientUid={uid} />
            </ProtectedRoute>
          }
        />

        {/* Gestion des chemins non trouvés */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
