import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
// import { getUserData } from "../users/getUserData";
import i18n from '../util/i18n'; // For changing the language
import { useTranslation } from 'react-i18next';


function SignInForm({uid, isAuthenticated, setIsAuthenticated, setUidValue, debug}) {
  
   
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    // const [uidCustomer, setUidCustomer] = useState(null);
    // const[dataFs, setDataFs]= useState(null);
    const { t } = useTranslation();

    // On récupère les paramètres de l'URL
    // console.log('URLparams', uid)
              

    // On vérifie si l'uid existe dans la collection customers de firestore
    const checkUid = async (uidToCheck) => {
      try {
        // console.log("Checking uid", uidToCheck);
        const doc = await firebase.firestore().collection('customers').doc(uidToCheck).get();
        return doc.exists;
      } catch (error) {
        console.log("Error getting document:", error);
        return false;
      }
    };

    useEffect(() => {
      const authenticateUser = async () => {
        let uidToCheck = uid;
        let isAuthenticated = false;

        // Check if uid is in URL
        if (!uidToCheck) {
          console.log("No uid in URL");
          uidToCheck = localStorage.getItem('uid');
          const expirationDate = new Date(localStorage.getItem('expirationDate'));
          // Check if uid is in localStorage and not expired
          if (!uidToCheck || expirationDate <= new Date()) {
            console.log("No uid in localStorage or expired");
            setIsAuthenticated(false);
            navigate('/home');
          }
        }
  
        // Check if uid is in firestore
        isAuthenticated = await checkUid(uidToCheck);

        // If uid is in firestore, save it to localStorage
        if (isAuthenticated) {
          // Save to localStorage if authenticated
          const expirationDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
          localStorage.setItem('uid', uidToCheck);
          localStorage.setItem('expirationDate', expirationDate.toISOString());


          // On parcours le document customers de firestore pour recuperer la langue de l'utilisateur + on l'envoie à i18n
          // On récupère également le nom et l'email de l'utilisateur à stocker dans le local storage (pour le chatbot HelpCrunch)
          firebase.firestore().collection('customers').doc(uidToCheck).get()
          .then((doc) => {
            if (doc.exists) {
              // console.log("Document data:", doc.data());
              const lang = doc.data().language;
              if (lang) {
                if (debug === true) console.log("langue de l'utilisateur (Firebase)", lang);

                i18n.changeLanguage(lang);
              }

              // On sauvegarde le type de l'utilisateur dans le local storage
              const client_type = doc.data().client_type;
              if (client_type) {
                localStorage.setItem('client_type', client_type);
              }

              /* const name = doc.data().name;
              // console.log("name", name);
              if (name) { 
                localStorage.setItem('name', name); 
              }
              else {
                localStorage.removeItem('name');
              }
              const email = doc.data().email;
              // console.log("email", email);
              if (email) { 
                localStorage.setItem('email', email); 
              }
              else {
                localStorage.removeItem('email');
              }
              window.dispatchEvent(new Event('storageUpdated')); */
            } else {
              // doc.data() will be undefined in this case
              // get OS language + split to get the first 2 letters (ex: fr)
              const lang = navigator.language.split('-')[0];
              // console.log("langue de l'utilisateur (Navigateur)", lang);
              i18n.changeLanguage(lang);
            }
          })
        } else {
          setErrorMessage(t('logout.error_id'));

          // Si l'uid n'est pas valide, alors on le passe à null pour éviter une infine loop (car l'uidValue est toujours dans l'App.jsx et est réutilisé)
          setUidValue(null);

          // On le déconnecte si l'UID provenant de l'url n'est pas valide (même si celle dans le local storage est valide)
          localStorage.removeItem('uid');
          localStorage.removeItem('expirationDate');
          localStorage.removeItem('name');
          localStorage.removeItem('email');
        }
  
        // Set isAuthenticated to true or false
        setIsAuthenticated(isAuthenticated);

        // Redirect to home (ProtectedRoute will redirect to deconnexion if not authenticated) + add debug=true to URL if needed
        if (debug === true) {
          navigate('/home?uid=' + uidToCheck + '&debug=true');
        }
        else {
          navigate('/home?uid=' + uidToCheck);
        }
      };
  
      authenticateUser();
    }, [uid, navigate, setIsAuthenticated, t, setUidValue, debug]);
   

    return (
      <>
        {errorMessage && (
          <div className="PhraseDisconnect">
            <p>{errorMessage}</p>
            <p>
              {t('logout.message')}{' '}
              {/* <a href="https://bv.elyote.com">{t('logout.link_message')}</a> */}
            </p>
          </div>
        )}
      </>
    );
}

export default SignInForm
/* function SignInForm({email, pwd, envoyerClientUid, envoyerData}) {
  
   
    const [errorMessage, setErrorMessage] = useState('');
    const history = useNavigate();
    const [uidCustomer, setUidCustomer] = useState(null);
    const[dataFs, setDataFs]= useState(null);
    const { t } = useTranslation();
   

    // on verif le signIn avec email pwd reçus de url
    useEffect(() => {
        const signInApp = async() => {
           await 
       firebase.auth().signInWithEmailAndPassword(email,pwd)
       
           .then((userCredential) => {
             
             var userId =userCredential.user._delegate.uid;
             console.log("signin a reussi")
             console.log('URLparams', userId)
             setUidCustomer(userId)
              envoyerClientUid(userId)
           
            //return getUserData(userId)

            // On parcours le document customers de firestore pour recuperer la langue de l'utilisateur + on l'envoie à i18n
            firebase.firestore().collection('customers').doc(userId).get()
            .then((doc) => {
              if (doc.exists) {
                // console.log("Document data:", doc.data());
                const lang = doc.data().language;
                if (lang) {
                  console.log("langue de l'utilisateur (Firebase)", lang);
                  i18n.changeLanguage(lang);
                }
              } else {
                // doc.data() will be undefined in this case
                // get OS language + split to get the first 2 letters (ex: fr)
                const lang = navigator.language.split('-')[0];
                console.log("langue de l'utilisateu (Navigateur)", lang);
                i18n.changeLanguage(lang);
              }
            })
            
            // Si l'utilisateur est bien authentifié, on le redirige vers la page HOME (affichage copieurs)
            history.push('/home');
            
           })
          
           .catch((error) => {
             var errorCode = error.code;
             var errorMessage = error.message;
             console.log("signin a echoue")
             setErrorMessage("merci de verifier vos identifiants")
             setDataFs(null)
           });
       
         }
         
          signInApp();
         
       }, []);
   

    return (
        
      <>    
        {errorMessage
            ? (<div className="PhraseDisconnect">
                    <p>{t('logout.error_id')}
                   </p>
                   <p>{t('logout.message')}
                   {' '} <a href="https://bv.elyote.com">{t('logout.link_message')}</a>
                   </p>
                </div>
                )
            : null
        }
            
      </>
    );
}

export default SignInForm */