import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ isAuthed, uid, isLoading, children }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Si l'utilisateur n'est pas authentifié mais qu'il accède à la page (VIA FAVORIS) /home?uid={uid}, on le connecte via la redirection vers /
  if (!isAuthed && uid) {
    return <Navigate to="/" />;
  }

  // Si l'utilisateur n'est pas authentifié ou que l'UID ne correspond pas, on le redirige vers la page de déconnexion
  if (!isAuthed || uid !== localStorage.getItem('uid')) {
    return <Navigate to="/deconnexion" />;
  }

  // Si tout est OK, on rend les enfants (composants imbriqués dans cette route protégée)
  return children;
};
